import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from './RelatedPass'
import './RelatedProducts.scss';

const RelatedProducts = (props) => {
  const postType = 'products'
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProducts(
            filter: { slug: {ne: "gatsby-demo" } },
            sort: { fields: date, order: DESC }
          ) {
            edges {
              node {
                ...ProductListFields
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} posts={data.allWordpressWpProducts} categoryFilter={data.categoryFilter} contentType="products" items={4} />}
    />
  );
}

export default RelatedProducts
