import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass'
import { RenderShortcode } from '../../RenderShortcode';
import './GlobalTrending.scss';

export const GlobalTrendingPass = props => {
  const { data, custom } = props;
  const { options } = data.siteSettings
  const trendingContent = custom ? props.trendingContent : options.trendingContent
  const trendingImage = custom ? props.trendingImage : options.trendingImage
  return (
    <div className="global-trending">
      <div className="wrap">
        <div className="inner">
          <div className="column">
            <RenderShortcode content={trendingContent}/>
          </div>
          <div className="image">
            <div className="inner">
              <ImagePass src={trendingImage}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export const GlobalTrending = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              trendingContent
              trendingImage {
                source_url
                localFile {
                  childImageSharp {
                    fixed(width: 540) {
                      ... GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          },
        }
      `}
      render={data => <GlobalTrendingPass {...props} data={data} />}
    />
  );
}
